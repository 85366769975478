import css from 'styled-jsx/css';
import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'badge';

export const badgeStyle = css`
  .badge {
    padding: 2px 8px;
    color: ${color.lighter};
    font-size: ${typography.size.s1};
    font-weight: ${typography.weight.medium};
    background: ${color.red};
    border-radius: 8px;
  }
`;
