// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/nextjs';

import {CaptureException} from '../../@types/pages.d';

import sentryOptions from '../constants/sentry';

import filterSensitiveFields from './sentryUtils';

type CustomSentry = (settings?: Sentry.NodeOptions) => {
  Sentry: typeof Sentry;
  captureException: (exception: CaptureException) => string;
};

const sentry: CustomSentry = (settings = {}) => {
  Sentry.init({...sentryOptions, ...settings});

  return {
    Sentry,
    captureException: ({err, reqCtx, ctx, errorInfo}) => {
      Sentry.configureScope((scope) => {
        if (err?.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (reqCtx) {
          const aux = filterSensitiveFields(reqCtx);

          Object.keys(aux).forEach((key) => {
            scope.setExtra(key, aux[key]);
          });
        }

        let statusCode: number;

        if (ctx?.res) {
          ({statusCode} = ctx.res);
        } else if (err) {
          ({statusCode} = err);
        }

        if (statusCode) {
          scope.setExtra('statusCode', statusCode);
        }

        // SSR only
        if (ctx) {
          const {req, res, query, pathname} = ctx;

          if (res?.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', 'false');
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);
          } else {
            scope.setTag('ssr', 'true');
            scope.setExtra('url', req.url);
            scope.setExtra('method', req.method);
            scope.setExtra('headers', req.headers);
            scope.setExtra('query', req.query);
            scope.setExtra('body', req.body);

            // On server-side we take session cookie directly from request
            if (req.cookies.sid) {
              scope.setUser({id: req.cookies.sid});
            }
          }
        }

        // TODO: try to log more errorInfo data
        if (errorInfo?.componentStack) {
          scope.setExtra('componentStack', errorInfo.componentStack);
        }
      });

      // This is an attempt to catch error when sentry
      // Couldn't send the error.
      try {
        return Sentry.captureException(err);
      } catch (error) {
        return Sentry.captureException(error);
      }
    },
  };
};

export default sentry;
