import resolverValidator from '../../features/NotificationSystem/validators';
import {ReducerActions, DashboardReducer} from './types';

const reducer: DashboardReducer = (state, action) => {
  const reducerActions: ReducerActions = {
    SET_ADVISOR: () => {
      if (action.type !== 'SET_ADVISOR') return state;

      return {
        ...state,
        advisor: action.payload,
      };
    },
    SET_GENERAL_NOTIFICATIONS: () => {
      if (action.type !== 'SET_GENERAL_NOTIFICATIONS') return state;

      const filteredNotifications = action.payload.filter((notification) => {
        const resolver = resolverValidator[notification.type.type];

        return resolver ? resolver(notification as GenericNotification) : true;
      });

      return {
        ...state,
        generalNotifications: filteredNotifications,
      };
    },
    SET_MESSAGES: () => {
      if (action.type !== 'SET_MESSAGES') return state;

      return {
        ...state,
        messagesNotification: action.payload,
      };
    },
    READ_MESSAGE: () => {
      if (action.type !== 'READ_MESSAGE') return state;

      return {
        ...state,
        messagesNotification: state.messagesNotification.filter(
          (message) => !action.payload.includes(message.id),
        ),
      };
    },
    READ_GENERAL_NOTIFICATION: () => {
      if (action.type !== 'READ_GENERAL_NOTIFICATION') return state;

      return {
        ...state,
        generalNotifications: state.generalNotifications.filter(
          (notification) => notification.id !== action.payload,
        ),
      };
    },
    ADD_GENERAL_NOTIFICATION: () => {
      if (action.type !== 'ADD_GENERAL_NOTIFICATION') return state;

      const notification = action.payload;

      const resolver = resolverValidator[notification.type.type];

      if (resolver && !resolver(notification as GenericNotification)) {
        return state;
      }

      return {
        ...state,
        generalNotifications: [notification, ...state.generalNotifications],
      };
    },
    ADD_MESSAGE_NOTIFICATION: () => {
      if (action.type !== 'ADD_MESSAGE_NOTIFICATION') return state;

      const notification = action.payload;

      return {
        ...state,
        messagesNotification: [notification, ...state.messagesNotification],
      };
    },
  };

  const reducerAction = reducerActions[action.type];

  if (!reducerAction) return state;

  return reducerAction();
};

export default reducer;
