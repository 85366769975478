import {NodeOptions} from '@sentry/node';
import {BrowserTracing} from '@sentry/tracing';

// Import and init Sentry release env variable
import BundleVersion from '../../bundleVersion';

process.env.SENTRY_RELEASE = BundleVersion;

const isProduction = process.env.NODE_ENV === 'production';
const sentryOptions: NodeOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  // Automatic instrumentation
  integrations: [new BrowserTracing()],
  // Uniform sample rate
  tracesSampleRate: 0.2,
  release: BundleVersion,
  attachStacktrace: true,
};

// Log sentry settings
sentryOptions.debug = !isProduction;

sentryOptions.beforeSend = (event, hint) => {
  const error = hint?.originalException as any;

  // Filtering Sentry internal error
  if (error?.message?.match(/prototype/i)) {
    return null;
  }

  return event;
};

export default sentryOptions;
