import request from '../../utils/request';

import {DataLayer} from './types';

export const ME_INCLUDES =
  'include=images,certifications,competencies,company,degrees,educations,experiences,profile,locations,integrations,calendar_preferences,features';

/**
 * Group Array by keys. Based on the TC39 specification
 * for Array.prototype.groupBy
 *
 * [View specification](https://github.com/tc39/proposal-array-grouping)
 *
 * @param array The array to be grouped
 * @param func A function that returns a string to be used as key
 * @returns A object with the key of the returned value of the function and the value of the array element
 */
export function groupBy<T extends string, K>(
  array: K[],
  func: (item: K, index: number, arr: K[]) => T,
): Record<T, K[]> {
  return array.reduce((acc, item, index, arr) => {
    const key = func(item, index, arr);

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);

    return acc;
  }, {} as Record<T, K[]>);
}

export const markAsRead = async (notificationIds: number[]) => {
  if (notificationIds[0] <= 0) return;

  await request('/api/core/notifications/mark-as-read', {
    method: 'POST',
    body: {notification_ids: notificationIds},
  });
};

export const dataLayer: DataLayer = ({event, values}) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...(values || {}),
    });
  }
};
