import React, {FC} from 'react';
import Image from 'next/image';

import {COMP_NAME, generalItemStyle} from './styles';
import resolver from '../../resolver';
import {unixToRelativeTime} from '../../utils';
import {useDashboardContextValue} from '../../../../stores/DashboardContext';

export interface GeneralItemProps {
  generalNotification: ZoeGeneralNotification;
}

const priorityMap: Record<number, string> = {
  30: 'low',
  20: 'medium',
  10: 'high',
};

const GeneralItem: FC<GeneralItemProps> = ({generalNotification}) => {
  const [{readNotification}] = useDashboardContextValue();

  const resolvedNotification = resolver(generalNotification);

  const priority = priorityMap[generalNotification.type.priority];
  const priorityClass = `${COMP_NAME}__priority-bar--${priority}`;

  const markAsRead = () => {
    readNotification(generalNotification.id);
  };

  return (
    <div className={COMP_NAME}>
      <div className={`${COMP_NAME}__priority-bar ${priorityClass}`} />

      <figure className={`${COMP_NAME}__icon`}>
        <Image
          width="16"
          height="16"
          src={resolvedNotification.icon}
          alt="notification icon"
        />
      </figure>

      <div className={`${COMP_NAME}__content`}>
        <div className={`${COMP_NAME}__content-header`}>
          <h5 className={`${COMP_NAME}__content-title`}>
            {resolvedNotification.title}
          </h5>

          {generalNotification.type.priority > 20 && (
            <figure className={`${COMP_NAME}__close`}>
              <Image
                width="14"
                height="14"
                src="/static/assets/icon_close_light_blue.svg"
                alt="mark notification read"
                onClick={markAsRead}
              />
            </figure>
          )}
        </div>

        {resolvedNotification.description && (
          <p className={`${COMP_NAME}__content-description`}>
            {resolvedNotification.description}
          </p>
        )}

        {resolvedNotification.Body && (
          <div>
            <resolvedNotification.Body
              notification={generalNotification as GenericNotification}
            />
          </div>
        )}

        <p className={`${COMP_NAME}__time`}>
          {unixToRelativeTime(generalNotification.created_at)} ago
        </p>
      </div>

      <style jsx>{generalItemStyle}</style>
    </div>
  );
};

export default GeneralItem;
