import React, {FC} from 'react';
import Image from 'next/image';

import {COMP_NAME, messagePlaceholderStyles} from './styles';

const MessagePlaceholder: FC = () => {
  return (
    <div className={COMP_NAME}>
      <Image
        width="56"
        height="56"
        src="/static/assets/icon_mail_message.svg"
        alt="no more messages icon"
      />

      <p className={`${COMP_NAME}__title`}>You don&apos;t have any messages.</p>

      <style jsx>{messagePlaceholderStyles}</style>
    </div>
  );
};

export default MessagePlaceholder;
