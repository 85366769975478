import React, {FC} from 'react';

import {notificationSystemStyles, COMP_NAME} from './styles';

import Tabs, {TabConfig} from '../../commons/Tabs';
import {useDashboardContextValue} from '../../stores/DashboardContext';

import MessagesNotifications from './components/MessageNotifications';
import GeneralNotifications from './components/GeneralNotifications';

const NotificationMenu: FC = () => {
  const [{generalNotifications, messagesNotification}] =
    useDashboardContextValue();

  const tabs: TabConfig[] = [
    {
      component: GeneralNotifications,
      id: 'task',
      title: 'Pending Tasks',
      badge: generalNotifications.length,
    },
    {
      component: MessagesNotifications,
      id: 'messages',
      title: 'Messages',
      badge: messagesNotification.length,
    },
  ];

  return (
    <div className={COMP_NAME}>
      <Tabs ignoreParams justifyTabs tabConfig={tabs} />
      <style jsx>{notificationSystemStyles}</style>
    </div>
  );
};

export default NotificationMenu;
