import resolverTitle from './title';
import resolverIcon from './icon';
import resolverDescription from './description';
import resolverBody from './body';

const resolveNotification = (notification: ZoeGeneralNotification) => {
  const notificationType = notification.type.type;

  const title = resolverTitle[notificationType](
    notification as GenericNotification,
  );
  const icon = resolverIcon[notificationType];
  const description = resolverDescription[notificationType]?.(
    notification as GenericNotification,
  );
  const Body = resolverBody[notificationType];

  return {
    title,
    icon,
    description,
    Body,
  };
};

export default resolveNotification;
