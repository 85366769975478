import {unixToRelativeTime} from '../../utils';

type ResolverFunction = <T extends GenericNotification>(
  notification: T,
) => string;

type ResolverDescription = Partial<
  Record<ZoeNotificationResolverTypes, ResolverFunction>
>;

const resolverDescription: ResolverDescription = {
  missing_survey({created_at}: MissingSurveyNotification) {
    /**
     * For the MissingSurveyNotification type of notification, the created_at attribute
     * is the same as the original booking start_time.
     */
    const relativeTime = unixToRelativeTime(created_at);

    return `It has been ${relativeTime} since your meeting. Please provide an update.`;
  },
  new_prospect(notification: NewProspectNotification) {
    const prospectName = notification.notifiable?.prospect?.name || 'prospect';

    return `Remember to view ${prospectName}'s information before your upcoming meeting.`;
  },
  missing_meeting_link: () =>
    'You are missing to add your video conference link.',
};

export default resolverDescription;
