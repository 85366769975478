import React, {
  FC,
  createContext,
  useContext,
  useReducer,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import LogRocket from 'logrocket';

import {dataLayer, markAsRead} from './utils';
import {LOGROCKET_ENABLED} from '../../utils/logRocket';

import {
  DashboardContextState,
  DashboardContextValue,
  AddToDataLayer,
  DataLayerEvent,
} from './types';
import reducer from './reducer';

export const DashboardContext = createContext(null);

export const DashboardContextProvider: FC<
  PropsWithChildren<DashboardContextState>
> = ({advisor, children}) => {
  const [queue, setQueue] = useState<Record<string, DataLayerEvent>>({});

  const [state, dispatch] = useReducer(reducer, {
    advisor,
    generalNotifications: [],
    messagesNotification: [],
  });

  const setAdvisor = (newAdvisor: Advisor) =>
    dispatch({
      type: 'SET_ADVISOR',
      payload: newAdvisor,
    });

  const readMessage = useCallback(async (messageIds: number[]) => {
    await markAsRead(messageIds);

    dispatch({
      type: 'READ_MESSAGE',
      payload: messageIds,
    });
  }, []);

  const readNotification = useCallback(async (notificationId: number) => {
    await markAsRead([notificationId]);

    dispatch({
      type: 'READ_GENERAL_NOTIFICATION',
      payload: notificationId,
    });
  }, []);

  const dequeue = (key: number) => {
    setQueue((s) => {
      const copy = {...s};

      delete copy[key];

      return copy;
    });
  };

  const addToDataLayer: AddToDataLayer = useCallback((event, values) => {
    const newEntry = {event, values};

    setQueue((s) => ({...s, [Object.keys(s).length]: newEntry}));
  }, []);

  const resetContext = useCallback(() => {
    setAdvisor(undefined);

    dispatch({
      type: 'SET_GENERAL_NOTIFICATIONS',
      payload: [],
    });

    dispatch({
      type: 'SET_MESSAGES',
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Ignore LogRocker for local development
    if (LOGROCKET_ENABLED && state.advisor?.id) {
      LogRocket.identify(state.advisor.id.toString(), {
        identifier: state.advisor.identifier,
        role: state.advisor.role,
      });
    }
  }, [state.advisor]);

  useEffect(() => {
    const keys = Object.keys(queue);

    if (state.advisor && keys.length) {
      const intKey = parseInt(keys[0], 10);

      dataLayer({
        event: queue[intKey].event,
        values: {
          ...queue[intKey].values,
          User_Id: state.advisor.id,
          User_Role: state.advisor.role,
          User_Name: state.advisor.full_name,
          User_Company: state.advisor.company?.name,
        },
      });
      dequeue(intKey);
    }
  }, [state.advisor, queue]);

  const extendedReducer = useMemo<DashboardContextValue>(() => {
    return [
      {
        ...state,
        setAdvisor,
        readMessage,
        readNotification,
        addToDataLayer,
        resetContext,
      },
      dispatch,
    ];
  }, [state, readMessage, readNotification, addToDataLayer, resetContext]);

  return (
    <DashboardContext.Provider value={extendedReducer}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContextValue = (): DashboardContextValue =>
  useContext<DashboardContextValue>(DashboardContext);
