import LogRocket from 'logrocket';

import {CustomPageContext, ErrorType} from '../../@types/pages.d';

// Copied from `node_modules/logrocket/dist/types.d.ts`
interface ICaptureOptions {
  tags?: {
    [tagName: string]: string | number | boolean;
  };
  extra?: {
    [tagName: string]: string | number | boolean;
  };
  // Custom Next SSR context object
  ctx?: CustomPageContext;
}

export type LogRocketException = (
  error: ErrorType,
  options: ICaptureOptions,
) => void;

// Ignore LogRocker for local development
export const LOGROCKET_ENABLED =
  process.env.NODE_ENV === 'production' &&
  process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
  process.env.NEXT_PUBLIC_LOGROCKET_ENVIRONMENT !== 'local';

export const logException: LogRocketException = (error, {tags, extra, ctx}) => {
  if (!LOGROCKET_ENABLED) return;

  const ssr = typeof window === 'undefined';
  const extendedExtra: ICaptureOptions['extra'] = {
    ...extra,
    ssr,
    statusCode: error?.statusCode,
  };

  if (!ssr) {
    extendedExtra.pageName = window.document.title;
    extendedExtra.pageUrl = document.location.href;
  }

  // Next SSR context
  if (ctx) {
    const {req, res, query, pathname} = ctx;

    extendedExtra.ctxStatusCode = res.statusCode;
    extendedExtra.ctxPathname = pathname;
    extendedExtra.reqUrl = req.url;
    extendedExtra.reqMethod = req.method;
    extendedExtra.reqParams = req.params;

    try {
      extendedExtra.ctxQuery = JSON.stringify(query);
    } catch (err) {
      // Do nothing
    }

    try {
      extendedExtra.reqHeaders = JSON.stringify(req.headers);
    } catch (err) {
      // Do nothing
    }

    try {
      extendedExtra.reqQuery = JSON.stringify(req.query);
    } catch (err) {
      // Do nothing
    }
  }

  LogRocket.captureException(error, {
    tags: {
      environment: process.env.NEXT_PUBLIC_LOGROCKET_ENVIRONMENT,
      source: 'default',
      ...tags,
    },
    extra: extendedExtra,
  });
};
