import React, {FC, useEffect, useState, useCallback} from 'react';
import {useRouter} from 'next/router';
import css from 'styled-jsx/css';

import request from '../../utils/request';
import {ME_INCLUDES, groupBy} from './utils';
import pusherWrapper from '../../utils/pusher';
import {
  isGeneralNotification,
  isMessage,
} from '../../features/NotificationSystem/utils';

import {isSecuredPath} from '../../utils/routeHelpers';

import {useDashboardContextValue} from '.';

const pusher = pusherWrapper();

const hideChatStyles = css.global`
  #jsd-widget {
    display: none;
  }
`;

const DashboardContextInit: FC = () => {
  const [{advisor, setAdvisor, addToDataLayer, resetContext}, dispatch] =
    useDashboardContextValue();
  const {asPath} = useRouter();

  const [loadingUser, setLoadingUser] = useState(false);
  const [pusherSubscribed, setPusherSubscribed] = useState(false);

  useEffect(() => {
    // Add session to context
    if (!advisor && isSecuredPath(asPath) && !loadingUser) {
      setLoadingUser(true);

      request(`/api/core/me?${ME_INCLUDES}`).then((res: {data: Advisor}) => {
        setAdvisor(res.data);
        setLoadingUser(false);

        // Init `Appcues` user
        window.Appcues?.identify(res.data.id, {
          role: res.data.role,
          name: res.data.name,
          email: res.data.email,
        });
      });
    }
  }, [advisor, asPath, loadingUser, setAdvisor]);

  useEffect(() => {
    // If no session, reset context and pusher
    if (advisor && !isSecuredPath(asPath)) {
      pusher.unsubscribe(`global-notifications-channel-${advisor.id}`);

      resetContext();
      setPusherSubscribed(false);
    }
  }, [advisor, asPath, resetContext]);

  const getNotifications = useCallback(() => {
    request('/api/core/notifications/unread').then(
      (res: {data: ZoeNotification[]}) => {
        const notifications = groupBy(res.data, (item) =>
          item.type.type === 'message_received' ? 'messages' : 'general',
        );

        const generalNotifications = notifications.general ?? [];

        addToDataLayer('NC');

        dispatch({
          type: 'SET_GENERAL_NOTIFICATIONS',
          payload: generalNotifications.filter(isGeneralNotification),
        });

        dispatch({
          type: 'SET_MESSAGES',
          payload: notifications.messages ?? [],
        });
      },
    );
  }, [addToDataLayer, dispatch]);

  useEffect(() => {
    if (advisor && isSecuredPath(asPath) && !pusherSubscribed) {
      setPusherSubscribed(true);

      // Link notifications pusher channel
      const channel = pusher.subscribe(
        `global-notifications-channel-${advisor.id}`,
      );

      channel.bind_global(
        (eventName: string, notification: GenericNotification) => {
          if (!notification) return;

          if (
            !notification?.notifiable ||
            eventName === 'notification.updated'
          ) {
            getNotifications();
          } else if (isGeneralNotification(notification)) {
            dispatch({
              type: 'ADD_GENERAL_NOTIFICATION',
              payload: notification as ZoeGeneralNotification,
            });
          } else if (isMessage(notification)) {
            dispatch({
              type: 'ADD_MESSAGE_NOTIFICATION',
              payload: notification as MessageReceivedNotification,
            });
          }
        },
      );

      getNotifications();
    }
  }, [advisor, pusherSubscribed, getNotifications, dispatch, asPath]);

  // Hide Advisor's help chat for admins
  if (advisor?.role === 'admin') {
    return <style jsx>{hideChatStyles}</style>;
  }

  return null;
};

export default DashboardContextInit;
