import css from 'styled-jsx/css';

export const COMP_NAME = 'messages-container';

export const messagesContainerStyle = css`
  .${COMP_NAME} {
    max-height: 80vh;
    overflow: auto;
  }
`;
