import css from 'styled-jsx/css';
import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'general-placeholder';

export const generalPlaceholderStyle = css`
  .${COMP_NAME} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 12px;

    &__title {
      margin: 4px 0;
      font-size: ${typography.size.m1};
      font-weight: ${typography.weight.medium};
      color: ${color.secondaryDark};
    }

    &__description {
      margin: 0;
      font-size: ${typography.size.s3};
      color: ${color.darker};
    }
  }
`;
