import React, {FC} from 'react';
import Image from 'next/image';

import {COMP_NAME, generalPlaceholderStyle} from './styles';

const GeneralPlaceholder: FC = () => {
  return (
    <div className={COMP_NAME}>
      <Image
        width="40"
        height="40"
        src="/static/assets/icon_success.svg"
        alt="all notifications read"
      />

      <p className={`${COMP_NAME}__title`}>You&apos;re awesome!</p>
      <p className={`${COMP_NAME}__description`}>
        You don&apos;t have any pending tasks!
      </p>

      <style jsx>{generalPlaceholderStyle}</style>
    </div>
  );
};

export default GeneralPlaceholder;
