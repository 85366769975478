import css from 'styled-jsx/css';
import {styles} from '@zoefin/design-system';

const {color} = styles;

export const COMP_NAME = 'tabs-component';

export const tabsStyle = css`
  .tabs-component {
    &__container {
      display: grid;
      grid-auto-columns: auto;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      gap: 47px;
      padding: 0px 70px;
      border-bottom: 1px solid #dcdcdc;

      &--justify {
        justify-content: space-evenly;
        padding: 0;
      }

      &--no-padding {
        padding: 0;
      }

      &--no-bottom-line {
        border-bottom: none;
      }
    }

    &__tab-item {
      position: relative;
      color: ${color.dark};
      cursor: pointer;
      padding: 0 1px;

      &--inactive {
        color: ${color.medium};
      }
    }

    &__tab-bar {
      margin-top: 2px;
      height: 4px;
      border-radius: 4px;
      background-color: ${color.bluePrimary};
    }

    &__tab-badge {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(calc(100% + 6px));
    }

    &__content {
      box-sizing: border-box;
    }
  }
`;
