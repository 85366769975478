import React, {FC} from 'react';
import {COMP_NAME, badgeStyle} from './styles';

const Badge: FC<PropsWithChildren> = ({children}) => {
  return (
    <>
      <div className={COMP_NAME}>{children}</div>

      <style jsx>{badgeStyle}</style>
    </>
  );
};

export default Badge;
