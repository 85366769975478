import React, {
  FC,
  ComponentType,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {GetServerSideProps} from 'next';
import {
  Auth0Context,
  ICache,
  useAuth0,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';

import {renderUtils} from '@zoefin/design-system';

import {GetServerSidePropsContextCustom} from '../../@types/next.d';

import request from './request';
import DynamicStorage from './dynamicStorage';
import {getFromPageContext} from './pageUtils';

interface Auth0ICache extends ICache {
  set<T = Auth0Cookie>(key: string, entry: T): Promise<void>;
  get<T = Auth0Cookie>(key: string): Promise<T | null>;
  remove(key: string): Promise<void>;
  allKeys?(): Promise<string[]>;
}

const emptyAuth0Cookie: Auth0Cookie = {};
const auth0Storage = new DynamicStorage('auth0Store', emptyAuth0Cookie);

export const deleteAuth0Token = () =>
  request('/api/local/session', {method: 'DELETE'}).catch(() => undefined);

export const cookieStorageCache: Auth0ICache = {
  async get<T extends Auth0Cookie>() {
    return auth0Storage.get() as Promise<T>;
  },
  async set<T extends Auth0Cookie>(_key: string, value: T) {
    const auth0Data = await auth0Storage.get();

    if (!!auth0Data.body?.access_token && !value.body?.access_token) return;

    await request('/api/local/session', {
      method: 'POST',
      body: {token: value},
    }).catch(() => undefined);

    await auth0Storage.set(value);
  },
  async remove() {
    await deleteAuth0Token();
    await auth0Storage.delete();
  },
  async allKeys() {
    return [];
  },
};

const defaultOnRedirecting = (): JSX.Element => null;

/**
 * If no session, redirects to Auth0 universal login
 *
 * Custom implementation based on `@auth0/auth0-react` withAuthenticationRequired method:
 * -> node_modules/@auth0/auth0-react/src/with-authentication-required.tsx
 *
 * @description [CSR | Client side] Renders page if user session exist, if not redirects to /login
 * @param Component
 * @param options
 * @returns FunctionComponent
 */
export const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      returnTo,
      onRedirecting = defaultOnRedirecting,
      claimCheck = (): boolean => true,
      loginOptions,
      context = Auth0Context,
    } = options;

    const {
      user,
      isAuthenticated,
      isLoading,
      buildAuthorizeUrl,
      loginWithRedirect,
      logout,
    } = useAuth0(context);

    const [loadingCookie, setLoadingCookie] = useState(true);
    const [cookieHasToken, setCookieHasToken] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    renderUtils.useMount(() => {
      // Verify if BE has a valid session stored in the cookie
      // By calling an endpoint the `routeFactory` can generate a new cookie if none
      request(`/api/local/session/verify`)
        .then(() => setCookieHasToken(true))
        .catch(() => setCookieHasToken(false))
        .finally(() => setLoadingCookie(false));
    });

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = isAuthenticated && claimCheck(user);

    const handleLogout = useCallback(async () => {
      /**
       * Start logout
       * KEEP `deleteAuth0Token` AND `logout` TOGETHER
       * WARNING: By modifying this section, you must go and update this same section from other files
       *
       * [client_id] is required to properly logout in all cases
       * Source: https://auth0.com/docs/logout/guides/redirect-users-after-logout
       */
      await deleteAuth0Token();

      logout({
        returnTo: `${window.location.origin}/login`,
        client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
      });
      /* End logout */
    }, [logout]);

    useEffect(() => {
      const hasAuth0Session = !isLoading && routeIsAuthenticated;
      const hasCookieSession = !loadingCookie && cookieHasToken;

      // Already redirecting to login page
      if (redirecting) return;

      if (
        (isLoading || routeIsAuthenticated) &&
        (loadingCookie || hasCookieSession)
      ) {
        return;
      }

      // If no local cookie, close Auth0 session
      if (hasAuth0Session && !hasCookieSession) {
        handleLogout();

        return;
      }

      (async (): Promise<void> => {
        const authorizeUrl = await buildAuthorizeUrl();
        const url = new URL(authorizeUrl);
        let finalReturnTo = `${window.location.pathname}${window.location.search}`;

        if (returnTo) {
          finalReturnTo =
            typeof returnTo === 'function' ? returnTo() : returnTo;
        }

        const opts = {
          ...loginOptions,
          redirectUri: url.searchParams.get('redirect_uri'),
          appState: {
            ...loginOptions?.appState,
            returnTo: finalReturnTo,
          },
        };

        setRedirecting(true);
        loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      routeIsAuthenticated,
      loadingCookie,
      cookieHasToken,
      loginOptions,
      returnTo,
      redirecting,
      handleLogout,
      buildAuthorizeUrl,
      loginWithRedirect,
    ]);

    return routeIsAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};

type WithPageAuthRequiredOptions<T = any> = {
  getServerSideProps?: GetServerSideProps<T>;
  returnTo?: string;
};

/**
 * @description [SSR | Server side] Renders page if user session exist, if not redirects to login
 * @param optsOrComponent
 * @returns GetServerSideProps
 */
export const withPageAuthRequired = (
  optsOrComponent: WithPageAuthRequiredOptions,
): GetServerSideProps => {
  return async (ctx: GetServerSidePropsContextCustom) => {
    const {getServerSideProps, returnTo} = optsOrComponent;
    const {baseURL} = getFromPageContext(ctx);

    /**
     * `ctx` object in newer Next versions doesn't pull the session object
     * from the BE in the request, AJAX is required instead.
     */
    const cookieHasToken = await request(
      `${baseURL}/api/local/session/verify`,
      {ctx},
    )
      .then(() => true)
      .catch(() => false);

    if (!cookieHasToken) {
      return {
        redirect: {
          destination: `/login?returnTo=${returnTo || ctx.req.url}`,
          permanent: false,
        },
      };
    }

    let ret: any = {props: {}};

    if (getServerSideProps) {
      ret = await getServerSideProps(ctx);
    }

    return {...ret, props: {...ret.props}};
  };
};

/**
 * @description [SSR | Server side] Don't render page if user has session
 * @param optsOrComponent
 * @returns
 */
export const withPageAuthSkipped = (
  optsOrComponent: WithPageAuthRequiredOptions,
): GetServerSideProps => {
  return async (ctx: GetServerSidePropsContextCustom) => {
    const {getServerSideProps, returnTo} = optsOrComponent;
    const {baseURL} = getFromPageContext(ctx);

    /**
     * `ctx` object in newer Next versions doesn't pull the session object
     * from the BE in the request, AJAX is required instead.
     */
    const cookieHasToken = await request(
      `${baseURL}/api/local/session/verify`,
      {ctx},
    )
      .then(() => true)
      .catch(() => false);

    if (cookieHasToken) {
      return {
        redirect: {
          destination: returnTo || `/advice-request`,
          permanent: false,
        },
      };
    }

    let ret: any = {props: {}};

    if (getServerSideProps) {
      ret = await getServerSideProps(ctx);
    }

    return {...ret, props: {...ret.props}};
  };
};
