import localForage from 'localforage';

const {NEXT_PUBLIC_STORAGE_DB_NAME} = process.env;

async function persistLocally(data: unknown): Promise<void> {
  try {
    await localForage.setItem(
      NEXT_PUBLIC_STORAGE_DB_NAME,
      JSON.stringify(data),
    );
  } catch (error) {
    // Do nothing

    console.log('error', error);
  }
}

/**
 * Allows to individually create multiple instances of the same `localStorage`
 * entity providing a single source of truth for data management
 *
 * @param storageName string
 * @param emptyState [Default value for an empty state]
 *
 * Usage:
 * `const storage = new Storage<string[]>('array', []);`
 *
 * TODO
 * - Encrypt (base64, AES, DES, Rabbit, RC4...)
 * - Proxy (programmatically get/set storage)
 * - Client side DB: IndexedDB (db.js?), Store.js
 * - Safari private mode
 */
export default class DynamicStorage<T> {
  storageName: string;

  emptyState: T;

  private localData: Record<string, T>;

  constructor(storageName: string, emptyState: T) {
    this.storageName = storageName;
    this.emptyState = emptyState;
  }

  private async getLocalData(): Promise<void> {
    try {
      const local = await localForage.getItem<string>(
        NEXT_PUBLIC_STORAGE_DB_NAME,
      );

      this.localData = local ? JSON.parse(local) : {};
    } catch (error) {
      this.localData = {};
    }
  }

  async get(): Promise<T> {
    await this.getLocalData();

    return this.localData?.[this.storageName] || this.emptyState;
  }

  /**
   * Standard usage: storage.set(...);
   *
   * Advanced usage (similar to `useState` hook):
   *  storage.set((prevStorage) => {
   *    return ...;
   *  });
   */
  async set(data: T | ((prevData: T) => T)): Promise<T> {
    await this.getLocalData();

    if (data instanceof Function) {
      const newStorageData = data(this.localData[this.storageName]);

      this.localData = {
        ...this.localData,
        [this.storageName]: newStorageData,
      };
    } else if (data) {
      this.localData = {
        ...this.localData,
        [this.storageName]: data,
      };

      await persistLocally(this.localData);
    }

    await this.getLocalData();

    return this.localData[this.storageName];
  }

  async delete(): Promise<void> {
    await this.getLocalData();

    this.localData[this.storageName] = this.emptyState;

    await persistLocally(this.localData);
  }
}

/**
 * Method to globally clear ALL data handled by `Storage` instances
 * Eg. user log out
 */
export async function clearStorage() {
  try {
    await localForage.removeItem(NEXT_PUBLIC_STORAGE_DB_NAME);
  } catch (error) {
    // Do nothing
  }
}
