import Pusher from 'pusher-js';

const pusherWrapper = (): Pusher => {
  const pusherKey = process.env.NEXT_PUBLIC_PUSHER_KEY ?? '';

  return new Pusher(pusherKey, {
    cluster: 'us2',
  });
};

export default pusherWrapper;
