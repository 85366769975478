const {NEXT_PUBLIC_STORAGE_DB_NAME} = process.env;

function persistLocally(data: unknown): void {
  try {
    localStorage.setItem(NEXT_PUBLIC_STORAGE_DB_NAME, JSON.stringify(data));
  } catch (error) {
    // Do nothing
  }
}

/**
 * Allows to individually create multiple instances of the same `localStorage`
 * entity providing a single source of truth for data management
 *
 * @param storageName string
 * @param emptyState [Default value for an empty state]
 *
 * Usage:
 * `const storage = new Storage<string[]>('array', []);`
 *
 * TODO
 * - Encrypt (base64, AES, DES, Rabbit, RC4...)
 * - Proxy (programmatically get/set storage)
 * - Client side DB: IndexedDB (db.js?), Store.js
 * - Safari private mode
 */
export default class Storage<T> {
  storageName: string;

  emptyState: T;

  private localData: Record<string, T>;

  constructor(storageName: string, emptyState: T) {
    this.storageName = storageName;
    this.emptyState = emptyState;
  }

  private getLocalData(): void {
    try {
      const local = localStorage?.getItem(NEXT_PUBLIC_STORAGE_DB_NAME);

      this.localData = JSON.parse(local);
    } catch (error) {
      this.localData = {};
    }
  }

  get(): T {
    this.getLocalData();

    return this.localData?.[this.storageName] || this.emptyState;
  }

  /**
   * Standard usage: storage.set(...);
   *
   * Advanced usage (similar to `useState` hook):
   *  storage.set((prevStorage) => {
   *    return ...;
   *  });
   */
  set(data: T | ((prevData: T) => T)): T {
    this.getLocalData();

    if (data instanceof Function) {
      const newStorageData = data(this.localData[this.storageName]);

      this.localData = {
        ...this.localData,
        [this.storageName]: newStorageData,
      };
    } else if (data) {
      this.localData = {
        ...this.localData,
        [this.storageName]: data,
      };

      persistLocally(this.localData);
    }

    return this.localData[this.storageName];
  }

  clear(): void {
    this.getLocalData();

    this.localData[this.storageName] = this.emptyState;

    persistLocally(this.localData);
  }
}

export const localStorageProvider = (): Map<string, unknown> => {
  try {
    const storage = new Storage('app-cache', '');

    // When initializing, we restore the data from `localStorage` into a map.
    if (typeof window !== 'undefined') {
      const map = new Map<string, unknown>(JSON.parse(storage.get() || '[]'));

      // Before unloading the app, we write back all the data into `localStorage`.
      window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()));

        storage.set(appCache);
      });

      // We still use the map for write & read for performance.
      return map;
    }

    return new Map();
  } catch (error) {
    return new Map();
  }
};
