export const isSecuredPath = (path: string): boolean => {
  const arrayPaths = [
    '/advice-request',
    '/profile',
    '/trading',
    '/dashboard',
    '/sketch',
    '/portfolio',
    '/rebalancing',
    '/account-detail',
    '/reconciliation',
    '/billing',
  ];

  // Ignore bundle assets
  if (path.includes('/_next')) {
    return false;
  }

  let doesNotHasMatch = true;

  arrayPaths.forEach((p) => {
    doesNotHasMatch = doesNotHasMatch && path.indexOf(p) !== 0;
  });

  return !doesNotHasMatch;
};

export default isSecuredPath;
