import css from 'styled-jsx/css';
import {styles} from '@zoefin/design-system';

const {filter, color, typography} = styles;

export const COMP_NAME = 'new-side-menu';

export const newSideMenuGlobalStyles = css.global`
  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .new-side-menu {
    &__items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 29px;
      width: 100%;

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin: 16px 0 0;
        width: 100%;
        height: 40px;
        cursor: pointer;

        p {
          display: flex;
          align-items: center;
          margin: 0;
          color: ${color.dark};
          font-size: ${typography.size.s3};
          line-height: 19px;

          img {
            margin-right: 8px;
          }
        }

        img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: ${color.blueLighter};
          width: 48px;
          border-radius: 4px;

          p {
            color: ${color.blueDarker};
          }

          img {
            filter: ${filter.blueDarker};
          }
        }

        &--expanded {
          justify-content: flex-start;
          padding-left: 24px;

          p span {
            opacity: 0;
            animation: 0.8s animate-fade 0.1s forwards;
          }

          &:hover {
            background-color: ${color.blueLighter};
            width: 100%;
            border-radius: 0 5px 5px 0;
          }
        }

        &--active {
          border-left: 3px solid ${color.bluePrimary};
          border-radius: 0 5px 5px 0;
          background-color: ${color.blueLighter};

          p {
            color: ${color.bluePrimary};
            font-weight: ${typography.weight.medium};
          }

          img {
            filter: ${filter.blueDarker};
          }

          &:hover {
            width: 100%;
            border-radius: 0 5px 5px 0;

            p {
              color: ${color.bluePrimary};
            }
          }
        }

        .ZUITooltip__message p {
          color: ${color.darker};
        }
      }
    }

    &__sub-items {
      display: flex;
      width: 100%;
      padding-left: 24px;
      box-sizing: border-box;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: ${color.medium};
      }

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin: 8px 0;
        width: 100%;
        height: 35px;
        cursor: pointer;

        p {
          display: flex;
          align-items: center;
          margin: 0;
          color: ${color.dark};
          font-size: ${typography.size.s3};
          line-height: 19px;

          img {
            margin-right: 8px;
          }
        }

        img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: ${color.blueLighter};
          border-radius: 4px;

          p {
            color: ${color.blueDarker};
          }

          img {
            filter: ${filter.blueDarker};
          }
        }

        &--active {
          border-left: 3px solid ${color.bluePrimary};
          border-radius: 0 5px 5px 0;
          background-color: ${color.blueLighter};

          p {
            color: ${color.bluePrimary};
            font-weight: ${typography.weight.medium};
          }

          img {
            filter: ${filter.blueDarker};
          }

          &:hover {
            width: 100%;
            border-radius: 0 5px 5px 0;

            p {
              color: ${color.bluePrimary};
            }
          }
        }

        .ZUITooltip__message p {
          color: ${color.darker};
        }
      }
    }
  }
`;

export const newSideMenuStyles = css`
  .new-side-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    width: 64px;
    min-width: 64px;
    padding: 20px 0;
    background-color: #fff;
    z-index: 151;
    transition: width 0.4s ease-in-out;

    &--expanded {
      width: 210px;
      min-width: 210px;
    }

    &__zoe-icon {
      display: flex;
      justify-content: center;
      min-height: 30px;
      width: 100%;

      &--expanded {
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 24px;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto 0 0;
      width: 100%;

      hr {
        border: 1px solid ${color.mediumLight};
        box-sizing: border-box;
        width: 76%;
        margin: 12px 0;
      }

      .new-side-menu__items_item {
        margin: 0;
      }
    }
  }
`;
