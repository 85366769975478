type ResolverFunction = <T extends GenericNotification>(
  notification: T,
) => string;

type ResolverTitle = Record<ZoeNotificationResolverTypes, ResolverFunction>;

const resolverTitle: ResolverTitle = {
  new_prospect: () => 'You have a new prospect!',
  missing_meeting_link: () => 'Add your video meeting link!',
  missing_survey: ({notifiable}: MissingSurveyNotification) => {
    return notifiable?.prospect?.full_name || '';
  },
};

export default resolverTitle;
