import React, {FC} from 'react';
import {useRouter} from 'next/router';

import {Button} from '@zoefin/design-system';

import {useDashboardContextValue} from '../../../../../stores/DashboardContext';

interface PendingMeetingLinkProps {
  notification: MissingMeetingLinkNotification;
}

const PendingMeetingLink: FC<PendingMeetingLinkProps> = () => {
  const {push} = useRouter();
  const [{addToDataLayer}] = useDashboardContextValue();

  const goToProfile = () => {
    push('/profile');

    addToDataLayer('NC_Notification', {
      Type: 'missing_meeting_link',
    });
  };

  return (
    <Button type="ghost" size="small" onClick={goToProfile}>
      Add Link
    </Button>
  );
};

export default PendingMeetingLink;
