import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'general-item';

export const generalItemStyle = css`
  .general-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 16px;

    &__icon {
      width: 24px;
      margin: 1px 9px 0 0;
    }

    &__content-title {
      margin: 0;
      font-size: ${typography.size.s3};
      font-weight: ${typography.weight.medium};
    }

    &__content-description {
      margin: 12px 0 16px 0;
      font-size: ${typography.size.s2};
      font-weight: ${typography.weight.regular};
      line-height: 17px;
    }

    &__time {
      color: ${color.mediumDark};
      font-size: ${typography.size.s1};
      font-weight: ${typography.weight.regular};
      margin-bottom: 0;
      line-height: 1;
    }

    &__content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__close {
      cursor: pointer;
      margin: 0;
    }

    &__priority-bar {
      width: 4px;
      border-radius: 0px 2px 2px 0px;
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0;

      &--high {
        background: ${color.red};
      }
    }
  }

  .general-item:not(:first-child) {
    border-top: 1px solid ${color.mediumLight};
  }
`;
