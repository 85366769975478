import React, {FC, useState, useEffect} from 'react';

import {useRouter} from 'next/router';
import Image from 'next/image';

import {Tooltip} from '@zoefin/design-system';

import {COMP_NAME, newSideMenuGlobalStyles, newSideMenuStyles} from './styles';
import {MENU_OPTIONS} from './constants';
import {MenuItem} from './types';

interface Props {
  onExpanded: (expanded: boolean) => void;
}

const NewSideMenu: FC<Props> = ({onExpanded}) => {
  const {push, asPath} = useRouter();

  const [isExpanded, setIsExpanded] = useState(false);

  const expandedModifier = isExpanded
    ? `${COMP_NAME}__items_item--expanded`
    : '';

  useEffect(() => {
    onExpanded(isExpanded);
  }, [isExpanded, onExpanded]);

  const handleClick = (url: string) => push(url);

  const subMenuItem = (item: MenuItem) => {
    const isActiveClass =
      item?.url && asPath.includes(item.url)
        ? `${COMP_NAME}__sub-items_item--active`
        : '';

    return (
      <div
        data-testid={item.message}
        className={`${COMP_NAME}__sub-items_item ${isActiveClass}`}
        key={item.message}
        onClick={() => item?.url && handleClick(item.url)}>
        <p>
          <span>{item.message}</span>
        </p>
      </div>
    );
  };

  const menuItem = (item: MenuItem) => {
    const isActiveClass =
      item?.url && asPath.includes(item.url)
        ? `${COMP_NAME}__items_item--active`
        : '';

    if (!isExpanded)
      return (
        <div
          data-testid={item.message}
          className={`${COMP_NAME}__items_item ${expandedModifier} ${isActiveClass}`}
          key={item.message}
          onClick={() => item?.url && handleClick(item.url)}>
          <Tooltip message={item.message} position="right">
            <img src={item.icon} alt="menu icon" />
          </Tooltip>
        </div>
      );

    return (
      <>
        <div
          data-testid={item.message}
          className={`${COMP_NAME}__items_item ${expandedModifier} ${isActiveClass}`}
          key={item.message}
          onClick={() => item?.url && handleClick(item.url)}>
          <p>
            <img src={item.icon} alt="menu icon" />
            <span>{item.message}</span>
          </p>
        </div>
        <div className={`${COMP_NAME}__sub-items`}>
          {!!item.subItems?.length &&
            item.subItems.map((subItem) => subMenuItem(subItem))}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`${COMP_NAME} ${
          isExpanded ? `${COMP_NAME}--expanded` : ''
        }`}>
        {isExpanded ? (
          <div
            className={`${COMP_NAME}__zoe-icon ${COMP_NAME}__zoe-icon--expanded`}>
            <Image
              src="/static/assets/zoe_logo_primary.png"
              alt="Zoe Financial"
              width={69}
              height={30}
            />
          </div>
        ) : (
          <div className={`${COMP_NAME}__zoe-icon`}>
            <Image
              src="/static/assets/zoe_logo_circle.svg"
              alt="Zoe Financial"
              width={24}
              height={28}
            />
          </div>
        )}

        <div className={`${COMP_NAME}__items`}>
          {MENU_OPTIONS.filter((item) => item.enabled).map((item) =>
            menuItem(item),
          )}
        </div>

        <div className={`${COMP_NAME}__footer`}>
          {!isExpanded && (
            <>
              <a
                data-testid="help-center"
                className={`${COMP_NAME}__items_item ${expandedModifier} `}
                href="https://zoefin.com/advisor-handbook/"
                target="_blank"
                rel="noreferrer">
                <Tooltip message="Help Center" position="right">
                  <img
                    src="/static/assets/icon_help_center.svg"
                    alt="Help Center icon"
                  />
                </Tooltip>
              </a>
              <hr />
              <div
                data-testid="expand-menu"
                onClick={() => setIsExpanded(!isExpanded)}
                className={`${COMP_NAME}__items_item ${expandedModifier} `}>
                <Tooltip message="Expand" position="right">
                  <img
                    src="/static/assets/icon_expand_outline.svg"
                    alt="expand icon"
                  />
                </Tooltip>
              </div>
            </>
          )}
          {isExpanded && (
            <>
              <a
                data-testid="help-center"
                className={`${COMP_NAME}__items_item ${expandedModifier}`}
                href="https://zoefin.com/advisor-handbook/"
                target="_blank"
                rel="noreferrer">
                <p>
                  <img
                    src="/static/assets/icon_help_center.svg"
                    alt="help center icon"
                  />
                  <span>Help Center</span>
                </p>
              </a>
              <hr />
              <div
                data-testid="collapse-menu"
                onClick={() => setIsExpanded(!isExpanded)}
                className={`${COMP_NAME}__items_item ${expandedModifier}`}>
                <p>
                  <img
                    src="/static/assets/icon_collapse_outline.svg"
                    alt="collapse icon"
                  />
                  <span>Collapse</span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx>{newSideMenuStyles}</style>
      <style jsx>{newSideMenuGlobalStyles}</style>
    </>
  );
};

export default NewSideMenu;
