import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'user-menu';

export const userMenuStyle = css`
  .user-menu {
    display: flex;
    align-items: center;

    &__notification {
      position: relative;
      cursor: pointer;
      padding: 7px 9px;
      border-radius: 6px;
      margin-right: 6px;
      user-select: none;
    }

    &__notification-count {
      position: absolute;
      top: 0px;
      right: -2px;
    }

    &__top {
      color: ${color.dark};
      font-size: ${typography.size.s4};
      font-weight: ${typography.weight.medium};
      display: flex;
      cursor: pointer;
      padding: 0 12px;
      transition: 0.15s background ease-in;
      border-radius: 6px;
      user-select: none;

      p {
        margin: 10px 10px 10px 0;
      }
    }

    &__content {
      z-index: 105;
      position: absolute;
      top: 72px;
      right: 0;
      transform: translate(-25px, 8px);
      background: #ffffff;
      box-shadow: 0px 8px 16px rgb(29 35 58 / 10%);
      border-radius: 6px;
      width: 400px;
      border: 1px solid ${color.light};
    }

    &__user_content {
      width: 300px;

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      .item-menu {
        &__logout {
          margin-left: 10px;
        }

        &--last {
          display: flex;
          align-items: center;
          padding: 18px 24px;
          color: #5f5f5f;
          line-height: 19px;
          text-decoration: none;
          transition: 0.15s background ease-in;
          cursor: pointer;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: calc(100% - 14px);
            height: 1px;
            background: ${color.mediumLight};
            left: 7px;
            top: 0;
          }

          &:hover {
            background: ${color.blueLighter};
          }
        }

        a {
          display: flex;
          align-items: center;
          padding: 18px 24px;
          color: #5f5f5f;
          line-height: 19px;
          text-decoration: none;
          transition: 0.15s background ease-in;

          &:hover {
            background: ${color.blueLighter};
          }
        }
      }
    }
  }

  .user-menu--open {
    background: ${color.blueLighter};
  }

  .user-menu__top:hover,
  .user-menu__notification:hover {
    background: ${color.blueLighter};
  }
`;
