import React, {ErrorInfo} from 'react';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
// eslint-disable-next-line import/no-unresolved
import {Analytics} from '@vercel/analytics/react';
import {Auth0Provider, AppState} from '@auth0/auth0-react';
import setupLogRocketReact from 'logrocket-react';
import LogRocket from 'logrocket';
import {SWRConfig} from 'swr';

import 'simplebar/dist/simplebar.min.css';

import {sanitizeInnerHtml} from '@zoefin/design-system';

import {AppPropsWithLayout} from '../../../@types/pages.d';

import {DashboardContextProvider} from '../../stores/DashboardContext';
import DashboardContextInit from '../../stores/DashboardContext/DashboardContextInit';

import sentry from '../../utils/sentry';
import request from '../../utils/request';
import {cookieStorageCache} from '../../utils/auth0Utils';
import {localStorageProvider} from '../../utils/storage';
import {LOGROCKET_ENABLED} from '../../utils/logRocket';

import initial from '../../styles';

import MainLayout from '../../layouts/MainLayout';

const {captureException} = sentry();

const onRedirectCallback = (appState: AppState) => {
  const returnTo =
    (Router.query.returnTo as string) ||
    appState?.returnTo ||
    '/advice-request';

  Router.push(returnTo);
};

class DashboardApp extends App<AppPropsWithLayout & AppProps, unknown> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    request('/api/local/log-error', {
      method: 'POST',
      body: {
        name: error.name,
        message: error.message,
        stack: errorInfo.componentStack || error.stack,
      },
    });

    captureException({err: error, errorInfo});
  }

  // Inform Appcues about each page redirection
  componentDidMount() {
    const {router} = this.props;

    // Ignore LogRocker for local development
    if (LOGROCKET_ENABLED) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
        shouldDebugLog: process.env.NODE_ENV !== 'production',
        shouldParseXHRBlob: true,
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });

      // After calling LogRocket.init()
      setupLogRocketReact(LogRocket);
    }

    window.Appcues?.page();
    router.events.on('routeChangeComplete', () => {
      window.Appcues?.page();
    });
  }

  getLayout() {
    const {Component, pageProps} = this.props;
    const page = <Component {...pageProps} />;

    if (Component.getLayout === null) {
      return page;
    }

    return Component.getLayout?.(page) || <MainLayout>{page}</MainLayout>;
  }

  render() {
    return (
      <>
        <Head>
          <title>Zoe Financial</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no"
          />

          {/* Google Tag Manager */}
          {sanitizeInnerHtml(
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5FZWLQ6');`,
            'script',
          )}
          {/* End Google Tag Manager */}
        </Head>

        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            title="google tag manager"
            src="https://www.googletagmanager.com/ns.html?id=GTM-MRNG2SF"
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}
          />
        </noscript>
        {/* End Google Tag Manager (noscript) */}
        <SWRConfig value={{provider: localStorageProvider}}>
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
            scope={process.env.NEXT_PUBLIC_AUTH0_SCOPE}
            audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
            onRedirectCallback={onRedirectCallback}
            redirectUri={
              typeof window !== 'undefined' &&
              `${window.location.origin}/callback`
            }
            cache={cookieStorageCache}
            useRefreshTokens>
            <DashboardContextProvider>
              <DashboardContextInit />
              {this.getLayout()}
            </DashboardContextProvider>
          </Auth0Provider>
        </SWRConfig>
        <Analytics />
        <style jsx>{initial}</style>
      </>
    );
  }
}

export default DashboardApp;
