import {cloneDeep} from 'lodash';

const bannedFields = [
  {key: 'password', type: 'string'},
  {key: 'cookie', type: 'string'},
  {key: 'Authorization', type: 'string'},
];

// Set object with all the keys of the deepSearch
const alreadySearchKey = new Set([]);

function checkObject(data: unknown): data is Record<string, unknown> {
  return data instanceof Object;
}

function deepSearch(data: Record<string, unknown>) {
  const newData = data;

  Object.keys(data).forEach((key: string) => {
    const aux = data[key];

    if (
      bannedFields.some(
        (banned) => banned.key === key && banned.type === typeof aux,
      )
    ) {
      newData[key] = '***';
    }
    // this validation was added because it was looping to infinite
    else if (checkObject(aux) && !alreadySearchKey.has(key)) {
      alreadySearchKey.add(key);
      deepSearch(aux);
    }
  });

  return newData;
}

function filterSensitiveFields(data: Record<string, unknown>) {
  // changed to cloneDeep by lodash, JSON.stringify was generated and error about circular dependency
  const newData = cloneDeep(data);

  return deepSearch(newData);
}

export default filterSensitiveFields;
