var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {BrowserTracing} from '@sentry/tracing';

const isProduction = process.env.NODE_ENV === 'production';

// Note: if you want to override the automatic release value, do not set a
// `release` value here - use the environment variable `SENTRY_RELEASE`, so
// that it will also get attached to your source maps
const sentryOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  integrations: [
    new BrowserTracing(),
    /**
     * Sentry replays temporarily disabled
     * In `@sentry/nextjs@7.28.0` they have an error that killed our monthly quota in just a day:
     *  Cannot read properties of undefined (reading 'prototype')
     *  https://sentry.io/organizations/zoe-financial/issues/3841328920/events/b8be117a23ee4c20b46642f1255db295/?project=6171717
     */
    // new Sentry.Replay(),
  ],
  // Uniform sample rate
  tracesSampleRate: 0.2,
  attachStacktrace: true,
  // 100% in development and a lower rate in production
  // replaysSessionSampleRate: 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
};

// Log sentry settings
sentryOptions.debug = !isProduction;
sentryOptions.onerror = !isProduction;

sentryOptions.beforeSend = (event, hint) => {
  const error = hint?.originalException;

  // Filtering Sentry internal error
  if (error?.message?.match(/prototype/i)) {
    return null;
  }

  return event;
};

Sentry.init(sentryOptions);
