import React, {FC} from 'react';
import {useRouter} from 'next/router';

import {Button} from '@zoefin/design-system';

import {useDashboardContextValue} from '../../../../../stores/DashboardContext';

interface UpdateZoeProps {
  notification: MissingSurveyNotification;
}

const UpdateZoe: FC<UpdateZoeProps> = ({notification}) => {
  const {push} = useRouter();
  const [{addToDataLayer}] = useDashboardContextValue();

  const goToSurvey = () => {
    push(
      `/advice-request/${notification.notifiable.identifier}?openModal=survey&source=notification`,
    );

    addToDataLayer('NC_Notification', {
      Type: notification.type.type,
    });
  };

  return (
    <Button type="ghost" size="small" onClick={goToSurvey}>
      Send update
    </Button>
  );
};

export default UpdateZoe;
