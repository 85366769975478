import moment from 'moment';

export const isMessage = (
  notification: ZoeNotification,
): notification is MessageReceivedNotification => {
  return notification.type.type === 'message_received';
};

export const isNewProspect = (
  notification: ZoeNotification,
): notification is NewProspectNotification => {
  return notification.type.type === 'new_prospect';
};

const generalEnabledTypes: Record<ZoeNotificationResolverTypes, boolean> = {
  missing_meeting_link: true,
  missing_survey: true,
  new_prospect: true,
};

export const isGeneralNotification = (
  notification: ZoeNotification,
): notification is ZoeGeneralNotification => {
  if (isMessage(notification)) return false;

  return !!generalEnabledTypes[notification.type?.type];
};

export const unixToRelativeTime = (unixTime: number): string => {
  const time = moment.unix(unixTime);
  const relativeTime = time.fromNow();

  return relativeTime.replace(' ago', '');
};
