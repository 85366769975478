type ResolverFunction = <T extends GenericNotification>(
  notification: T,
) => boolean;

type ResolverValidator = Partial<
  Record<ZoeNotificationResolverTypes, ResolverFunction>
>;

const resolverValidator: ResolverValidator = {
  missing_survey({notifiable}: MissingSurveyNotification) {
    const now = new Date().getTime();
    const meetingDate = notifiable?.meeting?.booking?.start_time || 0 * 1000;

    return now > meetingDate;
  },
};

export default resolverValidator;
