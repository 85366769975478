import css from 'styled-jsx/css';

export const COMP_NAME = 'general-container';

export const generalContainerStyle = css`
  .${COMP_NAME} {
    max-height: 80vh;
    overflow: auto;
  }
`;
