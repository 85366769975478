import {MenuItem} from './types';

export const MENU_ITEMS: MenuItem[] = [];
export const MENU_OPTIONS: MenuItem[] = [
  {
    enabled: true,
    message: 'Dashboard',
    url: '/dashboard',
    icon: '/static/assets/icon_dashboard.svg',
  },
  {
    enabled: true,
    message: 'Clients',
    url: '/advice-request',
    icon: '/static/assets/icon_clients.svg',
    subItems: [
      {
        enabled: true,
        message: 'Reconciliation',
        url: '/reconciliation',
      },
    ],
  },
  {
    enabled: true,
    message: 'Portfolios',
    url: '/portfolio',
    icon: '/static/assets/icon_portfolios.svg',
  },
  {
    enabled: true,
    message: 'Rebalancing',
    url: '/rebalancing',
    icon: '/static/assets/icon_rebalancing.svg',
  },
  {
    enabled: true,
    message: 'Trading',
    url: '/trading',
    icon: '/static/assets/icon_trading_outline.svg',
  },
  {
    enabled: true,
    message: 'Reporting',
    url: undefined,
    icon: '/static/assets/icon_reporting.svg',
  },
  {
    enabled: true,
    message: 'Billing',
    url: '/billing',
    icon: '/static/assets/icon_billing.svg',
  },
];
