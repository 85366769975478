import React, {FC} from 'react';
import {useRouter} from 'next/router';

import {Button} from '@zoefin/design-system';

import {useDashboardContextValue} from '../../../../../stores/DashboardContext';

interface NewProspectProps {
  notification: NewProspectNotification;
}

const NewProspect: FC<NewProspectProps> = ({notification}) => {
  const {push} = useRouter();
  const [{addToDataLayer}] = useDashboardContextValue();

  const goToAdvReq = () => {
    push(`/advice-request/${notification.notifiable.identifier}`);

    addToDataLayer('NC_Notification', {
      Type: notification.type.type,
    });
  };

  return (
    <Button type="ghost" size="small" onClick={goToAdvReq}>
      See Profile
    </Button>
  );
};

export default NewProspect;
