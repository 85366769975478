import React, {FC} from 'react';

import {useDashboardContextValue} from '../../../../stores/DashboardContext';
import {isGeneralNotification} from '../../utils';
import {COMP_NAME, generalContainerStyle} from './styles';

import GeneralItem from '../GeneralItem';
import GeneralPlaceholder from '../GeneralPlaceholder';

const GeneralNotifications: FC = () => {
  const [{generalNotifications}] = useDashboardContextValue();

  if (!generalNotifications.length) {
    return <GeneralPlaceholder />;
  }

  const notifications = generalNotifications.map((notification) => {
    if (isGeneralNotification(notification)) {
      return (
        <GeneralItem key={notification.id} generalNotification={notification} />
      );
    }

    return null;
  });

  return (
    <>
      <div className={COMP_NAME}>{notifications}</div>

      <style jsx>{generalContainerStyle}</style>
    </>
  );
};

export default GeneralNotifications;
