import React, {FC, useRef, BaseSyntheticEvent} from 'react';
import {useRouter} from 'next/router';
import Image from 'next/image';
import lineClamp from 'line-clamp';

import {renderUtils, sanitizeInnerHtml} from '@zoefin/design-system';

import {useDashboardContextValue} from '../../../../stores/DashboardContext';
import {unixToRelativeTime} from '../../utils';

import {COMP_NAME, messageNotificationStyle} from './styles';

const SENDER_TYPE: Partial<Record<UserRoles, string>> = {
  admin: 'Zoe Financial',
  prospect: 'Prospect',
  client: 'Client',
};

const SENDER_ICON: Partial<Record<UserRoles, string>> = {
  admin: '/static/assets/icon_zoe_circle_blue.svg',
  prospect: '/static/assets/icon_message.svg',
  client: '/static/assets/icon_message.svg',
};

const SENDER_PREFIX: Partial<Record<UserRoles, string>> = {
  admin: 'About',
  prospect: 'From',
  client: 'From',
};

interface MessageItemProps {
  messageNotification: MessageReceivedNotification;
}

const MessageItem: FC<MessageItemProps> = ({messageNotification}) => {
  const router = useRouter();
  const [{readMessage, addToDataLayer}] = useDashboardContextValue();

  const messageContent = useRef<HTMLParagraphElement>();

  const {sender, advice_request_prospect, description} =
    messageNotification.notifiable;

  const senderType = SENDER_TYPE[sender.role];
  const icon = SENDER_ICON[sender.role];
  const prefix = SENDER_PREFIX[sender.role];

  const isSenderAdmin = sender.role === 'admin';
  const titleName = isSenderAdmin
    ? advice_request_prospect.full_name
    : sender.full_name;

  renderUtils.useMount(() => {
    if (messageContent.current) {
      lineClamp(messageContent.current, 2);
    }
  });

  const markAsRead = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    readMessage([messageNotification.id]);
  };

  const goToMessage = () => {
    const routeParams =
      messageNotification.notifiable.sender.role === 'admin'
        ? ''
        : `?tab=activity&timeline-tab=prospect-chat&message-id=${messageNotification.notifiable.id}`;

    router.push(
      `/advice-request/${messageNotification.notifiable.advice_request_identifier}${routeParams}`,
    );

    addToDataLayer('NC_Notification', {
      Type: messageNotification.type.type,
    });
  };

  return (
    <>
      <div className={COMP_NAME} onClick={goToMessage}>
        <div className={`${COMP_NAME}__header`}>
          <figure className={`${COMP_NAME}__header_icon`}>
            <Image width="16" height="16" src={icon} alt="message badge" />
          </figure>

          <div className={`${COMP_NAME}__header_name`}>{senderType}</div>

          <div className={`${COMP_NAME}__divider`} />

          <figure className={`${COMP_NAME}__close`}>
            <Image
              width="14"
              height="14"
              src="/static/assets/icon_close_light_blue.svg"
              alt="mark notification read"
              onClick={markAsRead}
            />
          </figure>
        </div>

        <h5 className={`${COMP_NAME}__title`}>
          {prefix} {titleName}
        </h5>

        <p className={`${COMP_NAME}__message`} ref={messageContent}>
          {sanitizeInnerHtml(description, 'span')}
        </p>

        <p className={`${COMP_NAME}__time`}>
          {unixToRelativeTime(messageNotification.created_at)} ago
        </p>
      </div>

      <style jsx>{messageNotificationStyle}</style>
    </>
  );
};

export default MessageItem;
