export const DEFAULT_REQUEST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const INCLUDE_PARAMS =
  'profile,certifications,pricings,client_specializations,degrees,competencies,registeredStates,experiences,awards,educations,integrations,locations,images,calendar_preferences';

export const AR_INCLUDES =
  'notes,advisor.company,meeting.surveys,meeting.calls,prospect.incomes,prospect.liabilities,prospect.assets,prospect.flags,prospect.custodian_account_parameters,signature_documents.signers,features';

export const US_ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const FILE_EXTENSION_REGEXP = /\.\w+((?=[?#]\w+$)|$)/gim;
export const PHONE_MASK = [
  '(',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const ZIP_MASK = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

export const USER_INCLUDES =
  'flags,custodian_account_parameters,assets,liabilities,incomes';

export const AGE_VALUES: {[key: number]: string} = {
  25: 'Under 30',
  35: '30s',
  45: '40s',
  55: '50s',
  65: '60s',
  75: '70+',
};

export const RETIREMENT_VALUES: {[key: number]: string} = {
  15: '10+ years',
  10: '5-10 years',
  5: '1-5 years',
  1: 'Less than 1 year',
  0: 'Already retired',
};
