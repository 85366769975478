import {GetFromPageContext} from '../../@types/pages.d';

// eslint-disable-next-line import/prefer-default-export
export const getFromPageContext: GetFromPageContext = (ctx) => {
  const baseURL = ctx?.req
    ? `${ctx.req.headers['x-forwarded-proto']}://${ctx.req.headers.host}`
    : '';
  const headers = {cookie: ctx?.req ? ctx.req.headers.cookie : undefined};

  return {...ctx, baseURL, headers};
};
