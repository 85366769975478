import {isArray, isString, isNull, mapValues} from 'lodash';

import {isObject} from './objUtils';

/**
 * Remove "unnecessary" anidated data objects
 */
export const fuckDataObjects = (data: any): any => {
  if (!data) return null;

  const checkObject = (dataObj: Record<string, any>): Record<string, any> => {
    if (isArray(dataObj)) {
      return dataObj.map((d) => checkObject(d));
    }

    if (isObject(dataObj)) {
      return mapValues(dataObj.data || dataObj, (val) => checkObject(val));
    }

    return dataObj;
  };

  // In case { data: null }
  let newData = isNull(data.data) ? undefined : data.data || data;

  newData = checkObject(newData);

  return newData;
};

/**
 * Get error string from "weird" error objects
 */
export const getFuckingErrorString = (err: unknown): string => {
  const evaluateObj = (errorObj: any): string => {
    if (isString(errorObj)) {
      return errorObj;
    }

    let message = '';

    if (isArray(errorObj)) {
      return evaluateObj(errorObj[0]);
    }

    if (isObject(errorObj)) {
      // if (isObject(errorObj)) {
      if (errorObj.message || errorObj.msg) {
        return evaluateObj(errorObj.message || errorObj.msg);
      }

      if (errorObj.error) {
        return evaluateObj(errorObj.error);
      }

      message = errorObj[Object.keys(errorObj)[0]];

      return evaluateObj(message);
    }

    return message;
  };

  return evaluateObj(err);
};

export const arrayOfObjectsDifferences = (
  a: ObjectOfAny[],
  o: ObjectOfAny[],
  property: string,
  type: 'number' | 'string' = 'string',
): ObjectOfAny[] => {
  if (!a?.length || !o?.length) return [];

  return a.filter(
    (actual) =>
      !o.some((original) => {
        let newActualValue = actual[property];

        if (type === 'number') newActualValue = actual[property] || 0;

        return original[property] === newActualValue;
      }),
  );
};

export const arrayOfStringsDifferences = (
  a: string[],
  o: string[],
): string[] => {
  if (!a?.length || !o?.length) return [];

  return a.filter(
    (actual) => !o.some((original) => original === actual) && actual !== '',
  );
};
