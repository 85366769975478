import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'message-placeholder';

export const messagePlaceholderStyles = css`
  .${COMP_NAME} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0;

    &__title {
      color: ${color.darker};
      font-size: ${typography.size.s3};
    }
  }
`;
