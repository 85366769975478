function compareTwoObjects(
  newBlocks: Record<string, any>,
  controlBlocks: Record<string, any>,
): boolean {
  const newBlocksKeys = Object.keys(newBlocks).sort();
  const controlBlocksKeys = Object.keys(controlBlocks).sort();

  if (newBlocksKeys.join() !== controlBlocksKeys.join()) {
    return false;
  }

  return newBlocksKeys.every((key, i) => {
    const controlKey = controlBlocksKeys[i];

    const isControlObj = controlBlocks[controlKey] instanceof Object;
    const isNewObj = newBlocks[key] instanceof Object;

    if (isControlObj && isNewObj) {
      return compareTwoObjects(controlBlocks[controlKey], newBlocks[key]);
    }

    return controlBlocks[controlKey] === newBlocks[key];
  });
}

export const isObject = (value: unknown): boolean =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export default compareTwoObjects;
