import {ReactElement} from 'react';

import UpdateZoe from './UpdateZoe';
import NewProspect from './NewProspect';
import PendingMeetingLink from './PendingMeetingLink';

type ResolverFunction = <T extends GenericNotification>(props: {
  notification: T;
}) => ReactElement;

type ResolverBody = Partial<
  Record<ZoeNotificationResolverTypes, ResolverFunction>
>;

const resolverBody: ResolverBody = {
  missing_survey: UpdateZoe,
  new_prospect: NewProspect,
  missing_meeting_link: PendingMeetingLink,
};

export default resolverBody;
