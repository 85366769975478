import React, {FC, useState, useEffect} from 'react';
import {useRouter} from 'next/router';

import {COMP_NAME, tabsStyle} from './styles';
import Badge from '../../features/NotificationSystem/components/Badge';

export interface TabConfig {
  id: string;
  title: string;
  badge?: number;
  component: FC;
  isInactive?: boolean;
}

export interface TabsProps {
  tabConfig: TabConfig[];
  tabQueryParam?: string;
  ignoreParams?: boolean;
  justifyTabs?: boolean;
  noPadding?: boolean;
  keepBasePathParams?: boolean;
  noBottomLine?: boolean;
}

const Tabs: FC<TabsProps> = ({
  tabConfig,
  justifyTabs,
  ignoreParams,
  keepBasePathParams,
  tabQueryParam = 'tab',
  noPadding,
  noBottomLine,
}) => {
  const {query, push, asPath} = useRouter();
  const basePath = keepBasePathParams
    ? asPath.split('&').shift()
    : asPath.split('?').shift();

  const initialValue = !ignoreParams && query[tabQueryParam];
  const [currentTab, setCurrentTab] = useState(initialValue || tabConfig[0].id);

  const onClickTab = (tabId: string) => {
    setCurrentTab(tabId);

    if (ignoreParams) return;

    let newUrl = basePath;

    if (keepBasePathParams) {
      newUrl += `&${tabQueryParam}=${tabId}`;
    } else {
      newUrl += `?${tabQueryParam}=${tabId}`;
    }

    if (query?.adv) newUrl += `&adv=${query.adv}`;

    push(newUrl, undefined, {shallow: true});
  };

  const CurrentComponent = tabConfig.find(
    ({id}) => id === currentTab,
  )?.component;

  useEffect(() => {
    if (!ignoreParams) {
      setCurrentTab(query[tabQueryParam] || tabConfig[0].id);
    }
  }, [ignoreParams, query, tabConfig, tabQueryParam]);

  const justifyModifier = justifyTabs ? `${COMP_NAME}__container--justify` : '';
  const noPaddingModifier = noPadding
    ? `${COMP_NAME}__container--no-padding`
    : '';
  const noBottomLineModifier = noBottomLine
    ? `${COMP_NAME}__container--no-bottom-line`
    : '';

  return (
    <>
      <div
        className={`${COMP_NAME}__container ${justifyModifier} ${noPaddingModifier} ${noBottomLineModifier}`}>
        {tabConfig.map((tab) => {
          const inactiveTabModifier = tab?.isInactive
            ? `${COMP_NAME}__tab-item--inactive`
            : '';

          return (
            <span
              key={tab.id}
              className={`${COMP_NAME}__tab-item ${inactiveTabModifier}`}
              onClick={() => onClickTab(tab.id)}>
              {tab.title}

              {tab.id === currentTab && (
                <div className={`${COMP_NAME}__tab-bar`} />
              )}

              {!!tab.badge && (
                <div className={`${COMP_NAME}__tab-badge`}>
                  <Badge>{tab.badge}</Badge>
                </div>
              )}
            </span>
          );
        })}
      </div>

      <div className={`${COMP_NAME}__content`}>
        {CurrentComponent && <CurrentComponent />}
      </div>
      <style jsx>{tabsStyle}</style>
    </>
  );
};

Tabs.defaultProps = {
  tabQueryParam: undefined,
  ignoreParams: undefined,
  justifyTabs: undefined,
  noPadding: undefined,
  noBottomLine: undefined,
  keepBasePathParams: false,
};

export default Tabs;
