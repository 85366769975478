import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'message-item';

export const messageNotificationStyle = css`
  .${COMP_NAME} {
    position: relative;
    cursor: pointer;
    padding: 16px 24px;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
    }

    &__header_icon {
      margin: 0 8px 0 0;
    }

    &__header_name {
      font-size: ${typography.size.s1};
      font-weight: ${typography.weight.medium};
      color: ${color.mediumDark};
    }

    &__title {
      color: ${color.darker};
      margin: 0 0 8px 0;
      font-size: ${typography.size.s3};
    }

    &__message {
      margin: 0;
      font-size: ${typography.size.s2};
      color: ${color.darker};

      white-space: pre-wrap;
      overflow: hidden;
      line-height: 17px;
    }

    &__time {
      color: ${color.mediumDark};
      font-size: ${typography.size.s1};
      font-weight: ${typography.weight.regular};
      margin: 0;
    }

    &__divider {
      flex: 1 1 auto;
    }

    &__close {
      margin: 0;
    }
  }

  .message-item:hover {
    background: ${color.blueLighter};
  }

  .message-item:not(:first-child) {
    border-top: 1px solid ${color.mediumLight};
  }
`;
