import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export default css.global`
  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: ${typography.type.primary};
    color: ${color.darker};
    background-color: #f9f9f9;
    overflow-x: hidden;
  }

  *:focus {
    outline: none;
  }

  body p {
    line-height: 25px;
  }
`;
