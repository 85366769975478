import 'cross-fetch/polyfill';

import {getFuckingErrorString} from './dataHandling';
import sentry from './sentry';
import {logException} from './logRocket';
import {ReadClientCookie} from './index.d';

import {DEFAULT_REQUEST_HEADERS} from '../constants';

import {GetServerSidePropsContextCustom} from '../../@types/next.d';

interface CustomOptions extends RequestOptions {
  ctx?: GetServerSidePropsContextCustom;
}

const {captureException} = sentry();
const defaultOptions: CustomOptions = {
  method: 'GET',
  credentials: 'same-origin',
};

const fetchRequest = (
  url: string,
  finalOptions: ObjectOfAny,
  options: CustomOptions,
  resolve: (value?: unknown) => void,
  reject: (reason?: unknown) => void,
) => {
  let response: Response;

  fetch(url, finalOptions)
    .then(async (res) => {
      response = res;

      // TODO: confirm this use case from `@zoefin/utils`
      return res.status === 204 ? '' : res.json();
    })
    .then((body) => {
      if (response.ok) {
        resolve(body);
      } else {
        if (options && options.ignoreErrorManipulation) throw body;

        const error = getFuckingErrorString(body.errors || body);

        throw error;
      }
    })
    .catch((err) => {
      reject(err);

      // Log to Sentry
      captureException({
        err,
        reqCtx: {url, ...finalOptions},
      });

      // Log to logRocket
      logException(err, {
        tags: {
          source: 'request',
        },
        extra: {
          requestFetchUrl: url,
          ...finalOptions,
        },
      });
    })
    .finally(() => {
      // if server side, pass cookies to client
      if (options?.ctx) {
        const cookie = response.headers.get('set-cookie');

        options.ctx.res.setHeader('set-cookie', cookie);
      }
    });
};

async function request<T = any>(
  url: string,
  options?: CustomOptions,
): Promise<T> {
  const finalOptions: ObjectOfAny = {
    ...defaultOptions,
    ...options,
    headers:
      options && options.headers
        ? {...DEFAULT_REQUEST_HEADERS, ...options.headers}
        : DEFAULT_REQUEST_HEADERS,
  };

  // if server side, read cookies from request
  if (options?.ctx) {
    finalOptions.headers.cookie = options.ctx.req.headers.cookie;
  }

  /**
   * Required to consume HubSpot forms API
   *
   * WARNING!
   * Some advisors have `fetch` non-supported characters. In order to add support
   * client-side must use `decodeURI` and server-side `encodeURI`.
   */
  if (typeof window !== 'undefined') {
    finalOptions.headers.pageName = encodeURI(window.document.title);
  }

  if (options && options.body) {
    finalOptions.body = JSON.stringify(options.body);
  }

  return new Promise<T>((resolve, reject) => {
    fetchRequest(url, finalOptions, options, resolve, reject);
  });
}

export default request;

export const readClientCookie: ReadClientCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};
