import React, {FC} from 'react';

import {useDashboardContextValue} from '../../../../stores/DashboardContext';
import {isMessage} from '../../utils';
import {COMP_NAME, messagesContainerStyle} from './styles';

import MessageItem from '../MessageItem';
import MessagePlaceholder from '../MessagePlaceholder';

const MessagesNotifications: FC = () => {
  const [{messagesNotification}] = useDashboardContextValue();

  if (!messagesNotification.length) {
    return <MessagePlaceholder />;
  }

  const messages = messagesNotification
    .filter(isMessage)
    .map((message) => (
      <MessageItem messageNotification={message} key={message.id} />
    ));

  return (
    <>
      <div className={COMP_NAME}>{messages}</div>

      <style jsx>{messagesContainerStyle}</style>
    </>
  );
};

export default MessagesNotifications;
