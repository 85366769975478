/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useState, useEffect, FC} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useAuth0} from '@auth0/auth0-react';

import {renderUtils} from '@zoefin/design-system';

import {deleteAuth0Token} from '../../../utils/auth0Utils';
import {clearStorage} from '../../../utils/dynamicStorage';

import {useDashboardContextValue} from '../../../stores/DashboardContext';
import {userMenuStyle, COMP_NAME} from './styles';

import NotificationMenu from '../../../features/NotificationSystem';
import Badge from '../../../features/NotificationSystem/components/Badge';

type MenuTypes = 'user' | 'notifications';

const UserMenu: FC = () => {
  const [
    {advisor, generalNotifications, messagesNotification, addToDataLayer},
  ] = useDashboardContextValue();
  const {logout} = useAuth0();
  const router = useRouter();

  const prevPathName = renderUtils.usePrevious(router.pathname);

  const [currentMenu, setCurrentMenu] = useState<MenuTypes>();

  const pendingNotifications =
    generalNotifications.length + messagesNotification.length;

  const handleLogout = async () => {
    /**
     * Start logout
     * KEEP `deleteAuth0Token` AND `logout` TOGETHER
     * WARNING: By modifying this section, you must go and update this same section from other files
     *
     * [client_id] is required to properly logout in all cases
     * Source: https://auth0.com/docs/logout/guides/redirect-users-after-logout
     */
    await deleteAuth0Token();

    logout({
      returnTo: `${window.location.origin}/login`,
      client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    });

    clearStorage();
    /* End logout */
  };

  const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    const {currentTarget} = e;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setCurrentMenu(undefined);
      }
    }, 0);
  };

  const toggleMenu = (menu: MenuTypes) => {
    if (currentMenu === menu) {
      setCurrentMenu(undefined);
    } else {
      setCurrentMenu(menu);
    }
  };

  const onClickBell = () => {
    addToDataLayer('NC_Bell', {
      Page_Clicked: window.location.pathname,
      Notifications_Total: pendingNotifications,
    });
    toggleMenu('notifications');
  };

  useEffect(() => {
    if (router.pathname !== prevPathName) {
      setCurrentMenu(undefined);
    }
  }, [prevPathName, router.pathname]);

  const userActiveClass = currentMenu === 'user' ? `${COMP_NAME}--open` : '';
  const notificationsActiveClass =
    currentMenu === 'notifications' ? `${COMP_NAME}--open` : '';

  return (
    <>
      {/* The tabIndex is needed for the onBlur to work */}
      <div className={`${COMP_NAME}`} onBlur={onBlur} tabIndex={0}>
        {advisor && advisor.role !== 'admin' && (
          <div
            className={`${COMP_NAME}__notification ${notificationsActiveClass}`}
            onClick={onClickBell}>
            <Image
              src="/static/assets/ring-bell.svg"
              width="20px"
              height="23px"
              alt="Open Notifications"
            />

            {!!pendingNotifications && currentMenu !== 'notifications' && (
              <div className={`${COMP_NAME}__notification-count`}>
                <Badge>{pendingNotifications}</Badge>
              </div>
            )}
          </div>
        )}

        <div
          className={`${COMP_NAME}__top ${userActiveClass}`}
          onClick={() => toggleMenu('user')}
          data-testid="menu-btn">
          <p>Hi, {advisor?.name}</p>

          <img
            src="/static/assets/icon_arrow_down_gray_dark.svg"
            alt="Open Menu"
          />
        </div>

        {currentMenu === 'user' && (
          <div className={`${COMP_NAME}__content ${COMP_NAME}__user_content`}>
            <ul>
              {advisor && advisor.role !== 'admin' && (
                <>
                  <li className="item-menu">
                    <a
                      href="https://zoefin.com/advisor-handbook/"
                      target="_blank"
                      rel="noreferrer">
                      Advisor Support Page
                    </a>
                  </li>

                  <li className="item-menu">
                    <Link href="/profile?tab=availability">
                      <a>My Calendar</a>
                    </Link>
                  </li>

                  <li className="item-menu">
                    <Link href="/profile">
                      <a>My Profile</a>
                    </Link>
                  </li>
                </>
              )}

              <li
                className="item-menu item-menu--last"
                onClick={() => handleLogout()}>
                <img
                  src="/static/assets/icon_logout.svg"
                  alt="LogOut Menu"
                  width={20}
                  height={20}
                />
                <span className="item-menu__logout">Log Out</span>
              </li>
            </ul>
          </div>
        )}

        {currentMenu === 'notifications' && (
          <div className={`${COMP_NAME}__content`}>
            <NotificationMenu />

            <div className={`${COMP_NAME}__arrow`} />
          </div>
        )}
      </div>
      <style jsx>{userMenuStyle}</style>
    </>
  );
};

export default UserMenu;
