import React, {FC, useEffect, useState} from 'react';
import {useRouter} from 'next/router';

import {Button} from '@zoefin/design-system';

import {useDashboardContextValue} from '../../stores/DashboardContext/index';

import UserMenu from './UserMenu';

import {COMP_NAME, mainLayoutStyles} from './styles';
import NewSideMenu from './NewSideMenu';

type buttonInfoTypes = {goTo: string; title: string};

const BUTTON_INFO: buttonInfoTypes = {
  goTo: '/advice-request',
  title: 'Back to Home',
};

const MainLayout: FC<PropsWithChildren> = ({children}) => {
  const {pathname, isReady, query, push} = useRouter();
  const [{advisor}] = useDashboardContextValue();

  const [hasBtnBack, setHasBtnBack] = useState(false);
  const [isAdvReqView, setIsAdvReqView] = useState(false);
  const [isExpandedMenu, setIsExpandedMenu] = useState(false);

  const hasPlutus = advisor?.features?.includes('plutus');

  const goBackClass = (): string => {
    if (hasPlutus && isExpandedMenu)
      return 'header-plutus header-plutus--expanded';
    if (hasPlutus) return 'header-plutus';

    return '';
  };

  useEffect(() => {
    if (!isReady) return;

    const {identifier} = query;

    if (pathname.includes('/advice-request/[identifier]')) {
      setHasBtnBack(true);
    } else if (pathname.includes('/snapshot')) {
      BUTTON_INFO.goTo = `/advice-request/${identifier}/`;
      BUTTON_INFO.title = `Back to Prospect Info`;

      setHasBtnBack(true);
    } else {
      setHasBtnBack(false);
    }

    setIsAdvReqView(
      pathname.includes('/advice-request') &&
        identifier &&
        !pathname.includes('/snapshot'),
    );
  }, [pathname, isReady, query]);

  return advisor ? (
    <>
      <main className={`${COMP_NAME}__container`}>
        {hasPlutus && <NewSideMenu onExpanded={setIsExpandedMenu} />}
        <div
          className={`${COMP_NAME}__wrapper ${
            isExpandedMenu ? `${COMP_NAME}__wrapper--expanded` : ''
          }`}>
          <header className={`${COMP_NAME}__wrapper_header`}>
            <div className={`${COMP_NAME}__header_right`}>
              {hasBtnBack && isReady && (
                <div className={goBackClass()}>
                  <Button
                    type="secondary"
                    iconSrc="/static/assets/blue_left_arrow.svg"
                    iconPosition="left"
                    onClick={() => push(BUTTON_INFO.goTo)}>
                    Back to list
                  </Button>
                </div>
              )}
            </div>
            <div>
              <UserMenu />
            </div>
          </header>

          <section
            className={`${COMP_NAME}__wrapper_body ${
              !isAdvReqView ? 'default-page' : ''
            }`}>
            {children}
          </section>
        </div>
      </main>

      <style jsx>{mainLayoutStyles}</style>
    </>
  ) : (
    <main>{children}</main>
  );
};

export default MainLayout;
