import css from 'styled-jsx/css';

export const COMP_NAME = 'main-layout';

export const mainLayoutStyles = css`
  .main-layout {
    &__container {
      display: flex;
      height: 100vh;
    }

    &__wrapper {
      // 64px is the width of the side menu collapsed
      width: calc(100% - 64px);

      &--expanded {
        // 210px is the width of the side menu expanded
        width: calc(100% - 210px);
      }

      &_header {
        padding: 5px 25px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        z-index: 150;
        top: 0;
        left: 0;
        min-height: 65px;
      }
    }
  }

  .default-page {
    padding-top: 80px;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .header-plutus {
    margin: 7px 0 0 55px;

    &--expanded {
      margin-left: 195px;
    }
  }
`;
